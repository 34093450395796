import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'MOTORISTA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'MOTORISTA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function initForm(registro = {}) {
    return [
        initialize('motoristaForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true))
    	axios.get(`${consts.API_URL}/motorista`)
		.then(resp => {
			dispatch(setAguardando(false));
			dispatch({
		        type: 'MOTORISTA_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			dispatch(setAguardando(false));
			setErroAPI(e);
		});
	}
}

export function getListaCnh() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/motorista/cnh`)
		.then(resp => {
			dispatch(setAguardando(false));
			dispatch({
		        type: 'MOTORISTA_CNH_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			dispatch(setAguardando(false));
			setErroAPI(e);
		});
	}
}

export function getListaCpf() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/motorista/cpf`)
		.then(resp => {
			dispatch(setAguardando(false));
			dispatch({
		        type: 'MOTORISTA_CPF_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			dispatch(setAguardando(false));
			setErroAPI(e);
		});
	}
}

export function atualizarDataLake() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.post(`${consts.API_URL}/motorista/atualizarDataLake`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
		}).catch(e => {
			dispatch(setAguardando(false));
			setErroAPI(e);
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/motorista`, {
				...registro,
				cpf: String(registro.cpf || '').split(".").join("").replace("-", "").replace("/", ""),
				data_admissao: DateFormat.formatarDataTelaParaSql(registro.data_admissao),
				data_desligamento: DateFormat.formatarDataTelaParaSql(registro.data_desligamento),
				cnh_validade: DateFormat.formatarDataTelaParaSql(registro.cnh_validade),
				celular: FormatUtils.removerMascara(registro.celular),
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/motorista`, {
				...registro,
				cpf: String(registro.cpf || '').split(".").join("").replace("-", "").replace("/", ""),
				data_admissao: DateFormat.formatarDataTelaParaSql(registro.data_admissao),
				data_desligamento: DateFormat.formatarDataTelaParaSql(registro.data_desligamento),
				cnh_validade: DateFormat.formatarDataTelaParaSql(registro.cnh_validade),
				celular: FormatUtils.removerMascara(registro.celular),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/motorista?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaGrupo() {
    const request = axios.get(`${consts.API_URL}/grupo/listarSelect`);
    return {
        type: 'MOTORISTA_GRUPO_SELECT_LISTADO',
        payload: request
    };
}

export function consultarCPF(cpf, dataNascimento) {
	return (dispatch, getState) => {
		dispatch(setAguardando(true))
    	axios.get(`${consts.API_URL}/infoSimples/cpf?cpf=${FormatUtils.removerMascara(cpf)}&data_nascimento=${DateFormat.formatarDataTelaParaParametro(dataNascimento)}`)
		.then(resp => {
			dispatch(setAguardando(false));
			dispatch({
		        type: 'MOTORISTA_VALIDACAO_CPF_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			dispatch(setAguardando(false));
			setErroAPI(e);
		});
	}
}


export function limparConsultaCPF() {
	return (dispatch, getState) => {
		dispatch({
	        type: 'MOTORISTA_VALIDACAO_CPF_LISTADO',
	        payload: { data: null }
	    });
	}
}

